import React, { Suspense, createContext, useEffect, useState } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { AuthService } from './services/auth.service'
import TokenService from './services/TokenService'
import './App.css'
import _nav, { _navID } from './_nav'
import { CModal, CModalBody } from '@coreui/react'
import { triggerTimedOutModal } from './common/common'
import TimedOutModal from './components/Modals/TimedOutModal'
import { INACTIVE_EXPIRE_PERIOD } from './common/const'
import { UserService } from './services/user.service'
import 'animate.css';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const ConsumerDashboard = React.lazy(() => import('./views/pages/consumer/Dashboard'))
const CampaignCarousel = React.lazy(() =>
  import('./views/pages/main/campaign-main/CampaignCarousel'),
)

function App() {
  const [userLogin, setUserLogin] = useState(true)
  const [logOutModal, setLogOutModal] = useState(false)
  const [sessionTimeoutMsg, setSessionTimeoutMsg] = useState(
    'For your security, your session has expired due to inactivity. Please log in again to continue.',
  )

  useEffect(() => {
    const user = TokenService.getUser()

    if (!user?.expire && !user?.expirationPeriod) {
      logOut()

      return
    }

    if (user?.logOut) {
      logOut()
      window.location.reload(false)
      return
    }

    if (user?.jwt) {
      _nav[_navID.WHATSAPP].blocked = !user?.user?.whatsappService
      _nav[_navID.SMS].blocked = !user?.user?.smsService

      // if (user.user?.whatsappService) {
      //   _nav[_navID.WHATSAPP].items[_navID.SUB_INBOX_WHATSAPP].badge.text = 50
      // }

      setUserLogin(true)
    } else {
      logOut()
      // setUserLogin(true)
    }
  }, [])

  const logOut = () => {
    AuthService.logout()
    setUserLogin(false)
    // change status
  }

  const checkForInactivity = () => {
    let user = TokenService.getUser()
    console.log('checkForInactivity')

    if (user?.expire < Date.now()) {
      console.log('check')
      setSessionTimeoutMsg(
        "It's midnight, and your session has been reset for security reasons. Please log out and log in again to continue.",
      )
      setLogOutModal(true)

      return
    }

    const expireTime = user?.expirationPeriod
    if (expireTime < Date.now()) {
      if (user) {
        user.logOut = true
        TokenService.setUser(user)
      }

      setSessionTimeoutMsg(
        'For your security, your session has expired due to inactivity. Please log in again to continue.',
      )
      setLogOutModal(true)
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (userLogin) checkForInactivity()
    }, 5000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    updateBalance()
  }, [])

  const updateBalance = () => {
    let user = TokenService.getUser()
    if(!user) return
    UserService.getUserDetailsDataById(user.user.user_detail.id)
      .then((res) => {
        user.user.user_detail.balance = res.data[0].attributes.balance
        TokenService.setUser(user)
      })
      .catch((e) => console.log(e))
  }

  const updateExpireTime = () => {
    const expireTimePeriod = Date.now() + INACTIVE_EXPIRE_PERIOD

    const user = TokenService.getUser()

    if (user) {
      user.expirationPeriod = expireTimePeriod
      TokenService.setUser(user)
    }

    // localStorage.setItem('expireTime', expireTime)
  }

  useEffect(() => {
    const user = TokenService.getUser()
    if (userLogin && user) {
      updateExpireTime()

      window.addEventListener('click', updateExpireTime)
      window.addEventListener('keypress', updateExpireTime)
      window.addEventListener('scroll', updateExpireTime)
      window.addEventListener('mousemove', updateExpireTime)
    }

    return () => {
      window.addEventListener('click', updateExpireTime)
      window.addEventListener('keypress', updateExpireTime)
      window.addEventListener('scroll', updateExpireTime)
      window.addEventListener('mousemove', updateExpireTime)
    }
  }, [])

  return (
    <HashRouter>
      <TimedOutModal message={sessionTimeoutMsg} open={logOutModal} />
      <Suspense fallback={loading}>
        <Routes>
          <Route
            exact
            path="/register"
            name="Register Page"
            element={userLogin ? <Register /> : <Login />}
          />

          <Route
            exact
            path="/consumer/dash"
            name="Consumer Dashboard Page"
            element={userLogin ? <ConsumerDashboard /> : <Login />}
          />

          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route path="*" name="Dashboard" element={userLogin ? <DefaultLayout /> : <Login />} />
        </Routes>
      </Suspense>
    </HashRouter>
  )
}

export default App
