import { jwtDecode } from 'jwt-decode'

const localStorageName = 'usermysmsaqsghdhnbzxc3'

const getLocalRefreshToken = () => {
  const user = JSON.parse(localStorage.getItem(localStorageName))
  return user?.refreshToken
}

const getLocalAccessToken = () => {
  const user = JSON.parse(localStorage.getItem(localStorageName))
  return user?.accessToken
}

const updateLocalAccessToken = (token) => {
  let user = JSON.parse(localStorage.getItem(localStorageName))
  user.accessToken = token
  localStorage.setItem(localStorageName, JSON.stringify(user))
}

const getUser = () => {
  return JSON.parse(localStorage.getItem(localStorageName))
}

const getBalance = () => {
  return JSON.parse(localStorage.getItem(localStorageName))?.user?.user_detail?.balance
}

const getUsername = () => {
  return JSON.parse(localStorage.getItem(localStorageName))?.user?.username
}

const setUser = (user) => {
  localStorage.setItem(localStorageName, JSON.stringify(user))
}

const removeUser = () => {
  localStorage.removeItem(localStorageName)
}

const getToken = () => {
  return (
    JSON.parse(localStorage.getItem(localStorageName))?.jwt ||
    JSON.parse(localStorage.getItem(localStorageName))?.token
  )
}

const getTokenDetails = () => {
  const details = jwtDecode(JSON.parse(localStorage.getItem(localStorageName))?.jwt)
  return details
}

const updateBalance = (amount) => {
  let user = getUser()

  if (user && user.user && user.user.user_detail) {
    user.user.user_detail.balance -= amount
    setUser(user)
  }
}

const TokenService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  updateLocalAccessToken,
  getUser,
  setUser,
  removeUser,
  getUsername,
  getToken,
  getTokenDetails,
  updateBalance,
}

export default TokenService
