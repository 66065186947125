import { cilArrowRight, cilSave, cilXCircle } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { COLORS } from 'src/common/const'
import CorrectIconAnimation from 'src/assets/other/verified.gif'
import { AuthService } from 'src/services/auth.service'

function TimedOutModal({ open, message }) {
  const navigate = useNavigate()
  return (
    <CModal
      alignment="center"
      visible={open}
      backdrop="static"
      aria-labelledby="VerticallyCenteredExample"
    >
      <CModalHeader closeButton={false}>
        <CModalTitle id="VerticallyCenteredExample">Session Timeout!</CModalTitle>
      </CModalHeader>
      <CModalBody className="m-3">
        <div
          style={{
            textAlign: 'center',
          }}
          className="mb-3"
        >
          {/* <CIcon icon={cilSave} size="3xl" /> */}
          <span
            className="material-symbols-outlined"
            style={{ color: COLORS.DANGER_BTN, fontSize: 100 }}
          >
            person_off
          </span>
        </div>
        <p style={{ textAlign: 'center', fontSize: '1.2em' }}>{message}</p>
      </CModalBody>
      <CModalFooter>
        <CButton
          color="danger"
          style={{ color: 'white' }}
          onClick={() => {
            AuthService.logout()
            window.location.reload(false)
          }}
        >
          Sign Out
        </CButton>
      </CModalFooter>
    </CModal>
  )
}

export default TimedOutModal
