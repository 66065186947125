import React from 'react'
import CIcon from '@coreui/icons-react'
import { cibWhatsapp, cilShortText, cilStar, cilUserX } from '@coreui/icons'
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react'

export const _navID = {
  DASHBOARD: 0,
  SMS: 2,
  RESTRICTCUSTOMERS: 3,
  WHATSAPP: 4,
  SUB_INBOX_WHATSAPP: 2,
}

let _nav = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: (
      <span style={{ marginRight: 23, paddingLeft: 5 }} className="material-symbols-outlined">
        dashboard
      </span>
    ),
    blocked: false,
  },
  {
    component: CNavTitle,
    name: 'Managment',
  },
  {
    component: CNavItem,
    name: 'SMS Campaigns',
    to: '/campaigns',
    icon: (
      <span style={{ marginRight: 23, paddingLeft: 5 }} className="material-symbols-outlined">
        sms
      </span>
    ),
    blocked: false,
  },
  {
    component: CNavItem,
    name: 'Restricted Customers',
    to: '/restricted-customers',
    icon: <CIcon icon={cilUserX} customClassName="nav-icon" />,
    blocked: false,
  },
  {
    component: CNavGroup,
    name: <span style={{ fontWeight: 'bold'}}>Whatsapp</span>,
    to: '/whatsapp-campaigns',
    icon: <CIcon icon={cibWhatsapp} customClassName="nav-icon" />,
    blocked: false,
    items: [
      {
        component: CNavItem,
        name: 'Campaigns',
        to: '/whatsapp-campaigns',
      },
      {
        component: CNavItem,
        name: 'Templates',
        to: '/whatsapp-templates',
      },
      {
        component: CNavItem,
        name: 'Inbox',
        to: '/whatsapp-inbox',
        // badge: {
        //   color: 'danger',
        //   text: 0,
        // },
      }
    ],
  },
]

export default _nav
