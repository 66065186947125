import TimedOutModal from 'src/components/Modals/TimedOutModal'
import React from 'react'

export function getNullOrUndefinedAttributes(data) {
  const nullOrUndefinedAttributes = []
  for (const key in data) {
    if (data[key] === null || data[key] === undefined || data[key] === '') {
      nullOrUndefinedAttributes.push(key)
    }
  }
  return nullOrUndefinedAttributes
}

export function removeUndisfinedValuesInArray(selectArray) {
  const newData = selectArray.filter(function (element) {
    return element !== undefined
  })

  return newData
}

export function formatPhoneNumber(number) {
  console.log(number)
   if (number.startsWith('7')) {
    number = '0' + number;
  } else if (number.startsWith('94')) {
    number = '0' + number.slice(2);
  }

  console.log(number)

  return number

  // if (number.length === 11) {
  //   return number;
  // } else {
  //   return null;
  // }
}

export const truncateString = (inputString, maxLength) => {
  if (inputString.length > maxLength) {
    return inputString.slice(0, maxLength) + '...'
  }
  return inputString
}

export function removeEmptyAttributes(obj) {
  // Iterate over the object keys
  for (let key in obj) {
    // Check if the value is an object and not null
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      // Recursively remove empty attributes from nested objects
      removeEmptyAttributes(obj[key]);
      
      // Delete the key if the object is empty
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key];
      }
    }
    // Delete the key if the value is null, undefined, or an empty string
    else if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
      delete obj[key];
    }
  }
  return obj;
}

export function getNextMidnightTimestamp() {
  const now = new Date();
  const nextMidnight = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() + 1, // Move to the next day
    0, 0, 0, 0 // Set time to 12:00 AM
  );

  return nextMidnight.getTime(); // Get the timestamp in milliseconds
}



export const triggerTimedOutModal = () => <TimedOutModal open={true} />
