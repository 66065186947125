import { axiosInstance } from 'src/common/AxiosInstance'

export const UserService = {
  getUserDataByID: async (id, token, type) => {
    try {
      let response = null
      let filter = ''
      if (type == 'scheduled') {
        filter = `&filters[campaigns][scheduleType][$eq]=schedule`
      } else if (type == 'send_now') {
        filter = `&filters[campaigns][scheduleType][$eq]=send_now`
      } else {
        filter = ''
      }
      if (token)
        response = await axiosInstance.get(
          `users?populate=*&filters[id][$eq]=${id}${filter}&sort[0]=createdAt:desc`,
          {
            headers: {
              Authorization: token ? `Bearer ${token}` : '',
            },
          },
        )
      else
        response = await axiosInstance.get(
          `users?populate=*&filters[id][$eq]=${id}${filter}&sort[0]=createdAt:desc`,
        )
      return response.data
    } catch (error) {
      throw error
    }
  },
  getUserDetailsDataById: async (id) => {
    try {
      const response = await axiosInstance.get(`user-details?populate=*&filters[id][$eq]=${id}`)
      return response.data
    } catch (error) {
      throw error
    }
    
  },
}
